import React, { useState, useEffect } from "react";
import AdminLayout from "../layouts/AdminLayout";
import { Form, Container, ListGroup } from "react-bootstrap";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import adminhttp from "../../adminhttp";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
// import Stack from '@mui/material/Stack'
import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";
import AttachmentIcon from '@mui/icons-material/Attachment';
// import Card from '@mui/material/Card';
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AdminBreadCrumbs from "../components/AdminBreadCrumbs";
import { Link } from "react-router-dom";
// import Badge from '@mui/material/Badge';
import Badge from "react-bootstrap/Badge";
import { formatDate } from "../../components/PavilionConstants";
import DateIcon from "@mui/icons-material/InsertInvitation";
import Dropdown from "react-bootstrap/Dropdown";
import { Row, Col, Modal, Button } from "react-bootstrap";
// import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import EditIcon from "@mui/icons-material/DriveFileRenameOutlineTwoTone";
import SettingsIcon from "@mui/icons-material/SettingsTwoTone";
import MenusIcon from "@mui/icons-material/ListAltTwoTone";
import SponsorsIcon from "@mui/icons-material/Diversity2TwoTone";
import MultiBannerIcon from "@mui/icons-material/PermMediaTwoTone";
import StoreIcon from "@mui/icons-material/StoreTwoTone";
import RolesIcon from "@mui/icons-material/WorkTwoTone";
import MembershipsIcon from "@mui/icons-material/PeopleAltTwoTone";
import LibraryIcon from "@mui/icons-material/LibraryBooksTwoTone";
import EventIcon from "@mui/icons-material/EventAvailableTwoTone";
import EmailIcon from "@mui/icons-material/EmailTwoTone";
import PinsIcon from "@mui/icons-material/PushPinTwoTone";
import { useNavigate } from "react-router-dom";
import SponsorCard from "../components/AdminCards/SponsorCard";
import Alert from "@mui/material/Alert";
import RoleCard from "../components/AdminCards/RoleCard";
import MembershipCard from "../components/AdminCards/MembershipCard";
import AddMembership from "../components/AdminForms/AddMembership";
import Swal from "sweetalert2";
import TicketCard from "../components/AdminCards/TicketCard";
import AddTicket from "../components/AdminForms/AddTicket";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import Chip from '@mui/material/Chip';
import CRUDDropdown from "../../components/CRUDDropdown";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Divider from '@mui/material/Divider';
import AccordionDetails from '@mui/material/AccordionDetails';
import FsLightbox from "fslightbox-react";
import Spinner from "react-bootstrap/Spinner";
import { getPermissions } from "../utils/utils";
import { Navigate } from 'react-router-dom'

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: "rotate(0deg)",
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: "rotate(180deg)",
      },
    },
  ],
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
}));

function handleAction(row) {
  alert(row.id);
}

const Tickets = (props) => {

  const [loading, setLoading] = useState(true);
  const [noData, setNoData] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const data = location.state;
  const splitURL = location.pathname.toString().split("/");
  const [tickets, setTickets] = useState([]);
  const [pvlnName, setPvlnName] = useState("");
  const [rows, setRows] = useState(0);
  const [counter, setCounter] = useState(0);
  const [pavilionName, setPavilionName] = useState("");
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [filter, setFilter] = useState("0");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [open, setOpen] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to="/admin/dashboard">
      Dashboard
    </Link>,
    <Typography key="3" sx={{ color: "text.primary" }}>
      Ticket Requests
    </Typography>,
  ];

  
  const [searchResults, setSearchResults] = useState([]);

  // Handle search input change
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setValue(query);

    if (query.trim() === "") {
      setSearchResults(tickets); // Reset to all tickets if the query is empty
    } else {
      const filteredTickets = tickets.filter((ticket) => {
        return (
          ticket.name.toLowerCase().includes(query) || // Search by name
          ticket.email.toLowerCase().includes(query) || // Search by email
          ticket.subject.toLowerCase().includes(query) // Search by subject
        );
      });
      setSearchResults(filteredTickets);
    }
  };

  const handleSelect = (e) => {
    setFilter(e.target.value);
  };

  const [apiPath, setApiPath] = useState();

const showAlert = () => {
  if (Object.values(permissions).length > 0 && !permissions['view-tickets']) {
    Swal.fire({
      title: "Permission Denied",
      text: "You don't have permission, please contact Administrator",
      icon: "warning",
      confirmButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/admin/dashboard");
      }
    });
  }
};


  const handleRequest = () => {
    if (filter === "0") {
      adminhttp().get(`/admin/tickets`).then((data) => {
          if (data.data.data.length > 0) {
            setLoading(true)
            setTickets(data.data.data);
            setLoading(false)
          }else{
            setNoData(true)
            setLoading(false)
          }
        });
    }
    if (filter === "1") {
      adminhttp()
        .get(`/admin/tickets/inprogress`)
        .then((data) => {
          if (data.data.data.length > 0) {
            setLoading(true)
            setTickets(data.data.data);
            setLoading(false)
          } else {
            setNoData(true)
            setLoading(false)
          }
        });
    }
    if (filter === "2") {
      adminhttp()
        .get(`/admin/tickets/completedTickets`)
        .then((data) => {
          if (data.data.data.length > 0) {
            setLoading(true)
            setTickets(data.data.data);
            setLoading(false)
          }else{
            setNoData(true)
            setLoading(false)
          }
        });
    }
    if (filter === "3") {
      adminhttp()
        .get(`/admin/tickets/closed`)
        .then((data) => {
          if (data.data.data.length > 0) {
            setLoading(true)
            setTickets(data.data.data);
            setLoading(false)
          }else{
            setNoData(true)
            setLoading(false)
          }
        });
    }
  };
  const [permissions, setPermissions] = useState({});
  const [editModalShow, setEditModalShow] = useState(false);
  const [currentTicket, setCurrentTicket] = useState(null);

  const handleEdit = (ticket) => {
    setCurrentTicket(ticket); // Store the selected ticket data
    setEditModalShow(true); // Open the edit modal
  };

  const handleDelete = () => {
    //setShow(true)
  };

  const handleDropdown = (e) => {
    e.stopPropagation();
    //setShow(true)
  };

  const handleAttachment = (attachment)=>(e)=> {
   e.stopPropagation();
   setOpen(!open);
    setAttachments([`${localStorage.getItem('bucketName')}/images/tickets/${attachment}`]);
  };

  const handleUpdateTicket = () => {
    adminhttp()
      .post(`/admin/tickets/update`, currentTicket)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "Ticket updated successfully.",
          icon: "success",
        }).then(() => {
          setEditModalShow(false); // Close the modal
          handleRequest(); // Refresh the ticket list
        });
      })
      .catch((error) => {
        Swal.fire({
          title: "Error!",
          text: "Failed to update the ticket.",
          icon: "error",
        });
      });
  };



  useEffect(() => {
    handleRequest();
  }, [filter]);

  // Update search results when tickets change
  useEffect(() => {
    setSearchResults(tickets);
  }, [tickets]);

  useEffect(() => {
     getPermissions().then((res)=> (setPermissions(res)))
  },[]);

  return (
    <>
      <AdminLayout>
      {loading && <Box display="flex" flexDirection="column" justifyContent="center" alignItems='center' height='100%'>
      <Spinner size='lg' className='me-2 ' animation='border' />
 </Box>}
        {(Object.values(permissions).length>0 && permissions['view-tickets']) ? <Container fluid className="bg-light">
          <Box component="section" sx={{ px: 2, pt: 2, pb: 1 }}>
            <AdminBreadCrumbs breadcrumbs={breadcrumbs} />
          </Box>

          <Box
            component="section"
            className="d-flex align-items-center justify-content-between"
            sx={{ px: 2, pt: 0, pb: 1 }}
            data-aos="fade-up"
          >
            <h4>Tickets</h4>
            <div className="d-flex justify-content-end align-items-center">
              <Form className="me-2">
                <Form.Control
                  type="search"
                  size="small"
                  value={value}
                  onChange={handleSearch}
                  placeholder="Search Tickets"
                />
              </Form>
              <Form.Select
                size="small"
                onChange={handleSelect}
                className="w-auto me-2"
              >
                <option selected value="0">
                  New Tickets
                </option>
                <option value="1">Inprogress Tickets</option>
                <option value="2">Completed Tickets</option>
                <option value="3">Closed Tickets</option>
              </Form.Select>

            </div>
          </Box>

          <ListGroup variant="flush">
            <ListGroup.Item className="bg-transparent">
              <Accordion className='rounded-4 bg-secondary mb-2'>
                <AccordionSummary sx={{ flexDirection: 'row-reverse' }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Box sx={{ flexGrow: 1 }} className='ms-3 my-0'>
                    <Grid container spacing={2} >
                    <Grid item xs={6} md={1} >
                        <Typography variant="body" component="p" className='mt-1 text-light' >
                          #
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={1.5} >
                        <Typography variant="body" component="p" className='mt-1 text-light' >
                          Date
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={1.5} >
                        <Typography variant="body" component="p" className='mt-1 text-light' >
                          Name
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={2} className='d-flex align-items-center'>
                        <Typography variant="body" component="p" className='text-light'>
                          Subject
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={3} className='d-flex align-items-center'>
                        <Typography variant="body" component="p" className='text-light'>
                          Email
                        </Typography>
                      </Grid>
                      <Grid item xs={6} md={1} className='d-flex align-items-center'>
                        <Typography variant="body" component="p" className='text-light'>
                          Priority
                        </Typography>
                      </Grid>
                      
                      <Grid item xs={6} md={1} className='d-flex align-items-center'>
        <Typography variant="body" component="p"   className='text-light'>
                <AttachmentIcon/>
         </Typography>                    
        </Grid>
                      <Grid item xs={6} md={1} className='d-flex align-items-center justify-content-end' >
                        <Typography variant="body" component="p" className='text-light'>
                          Actions
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </AccordionSummary>
              </Accordion>
    {loading ? (
      <Box sx={{ flexGrow: 1, textAlign:'center' }}>
          <Spinner size='lg' className='me-2 ' animation='border' />
      </Box>
    ):(      
             searchResults.length > 0 && (
                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    spacing={{ xs: 1, md: 1 }}
                    columns={{ xs: 1, md: 12 }}
                  >
                    {searchResults.map((ticket, index) => (
                      <Grid item xs={12} md={12} key={index}>
                        {/* <TicketCard
                          data={ticket}
                          handleRequest={handleRequest}
                        /> */}
                        <Accordion className='rounded-4 bg-toggle'>
                          <AccordionSummary sx={{ flexDirection: 'row-reverse' }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                          >

                            <Box sx={{ flexGrow: 1 }} className='ms-3 my-0'>
                              <Grid container spacing={2} >
                              <Grid item xs={6} md={1} className='d-flex align-items-center'>
                                  <Typography variant="body" component="p" className='' >
                                    {ticket.ticket_id}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} md={1.5} className='d-flex align-items-center'>
                                  <Typography variant="body" component="p" className='mt-1' >
                                    {formatDate(ticket.created_at)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} md={1.5} className='d-flex align-items-center'>
                                  <Typography variant="body" component="p" >
                                    {ticket.name}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} md={2} className='d-flex align-items-center'>
                                  <Typography variant="body" component="p" >
                                    {ticket.subject}
                                  </Typography>
                                </Grid>
                               
                               <Grid item xs={6} md={3} className='d-flex align-items-center' style={{wordBreak: 'break-all'}}>
                                  <Typography variant="body" component="p" >
                                    {ticket.email}
                                  </Typography>
                                </Grid>

                                <Grid item xs={6} md={1} className='d-flex align-items-center' >
                                  <Typography variant="body" component="p" >
                                    <Chip label={ticket.priority} variant='outlined' size='small' color={ticket.priority === 'LOW' ? 'success' : (ticket.priority === 'HIGH' ? 'error' : 'warning')} />
                                  </Typography>
                                </Grid>
                          
                                <Grid item md={1} className='d-flex align-items-center' >
        {ticket.attachments && (
        <Typography variant="body" component="p" >
        <Avatar onClick={handleAttachment(`${ticket.attachments}`)} variant='square' src={`${localStorage.getItem('bucketName')}/images/tickets/${ticket.attachments}`} name={ticket.name} size='50'/> 
         </Typography>                    
         )}
        </Grid>
                                <Grid item xs={6} md={1} className='d-flex align-items-center justify-content-end' >
                                  <CRUDDropdown
                                    title={<MoreVertIcon fontSize="small" />}
                                    handleDropdown={(e) => handleDropdown(e)}
                                    addTitle={permissions['update-tickets'] && "Edit"}
                                    addClick={() => handleEdit(ticket)}
                                  /*   editTitle={
                                       ticket.status === 1
                                         ? "Deactivate"
                                         : "Activate"
                                     }*/
                                  /*editClick={handleStatus}*/
                                  /*  deleteTitle="ACL" */
                                  />
                                </Grid>
                              </Grid>
                            </Box>

                          </AccordionSummary>
                          <Divider />
                          <AccordionDetails>
                            <h5>Description</h5>
                            {ticket.description}
                          </AccordionDetails>
                        </Accordion>




                      </Grid>
                    ))}
                  </Grid>
                </Box>
              ))}

              {noData && (
                <Box
                  sx={{ flexGrow: 1 }}
                  className="d-flex justify-content-center"
                >
                  <Alert variant="outlined" severity="error">
                    Tickets not found
                  </Alert>
                </Box>
               )}
            </ListGroup.Item>
          </ListGroup>

          <Modal show={editModalShow} onHide={() => setEditModalShow(false)} centered>
            <Modal.Header closeButton>
              <Modal.Title>Edit Ticket</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {currentTicket && (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleUpdateTicket(); // Call update function on submit
                  }}
                >
                  {/* <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={currentTicket.name}
                      disabled // Disable this field
                      onChange={(e) =>
                        setCurrentTicket({ ...currentTicket, name: e.target.value })
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      disabled // Disable this field
                      value={currentTicket.email}
                      onChange={(e) =>
                        setCurrentTicket({ ...currentTicket, email: e.target.value })
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      type="text"
                      disabled // Disable this field
                      value={currentTicket.subject}
                      onChange={(e) =>
                        setCurrentTicket({ ...currentTicket, subject: e.target.value })
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      disabled // Disable this field
                      rows={3}
                      value={currentTicket.description}
                      onChange={(e) =>
                        setCurrentTicket({ ...currentTicket, description: e.target.value })
                      }
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Priority</Form.Label>
                    <Form.Select name="priority" id="priority"
                      value={currentTicket.priority}
                      onChange={(e) =>
                        setCurrentTicket({ ...currentTicket, priority: e.target.value })
                      }
                    >
                      <option value="">Select Priority</option>
                      <option value="LOW">Low</option>
                      <option value="MEDIUM">Medium</option>
                      <option value="HIGH">High</option>
                    </Form.Select>
                  </Form.Group> */}
                  <Form.Group className="mb-3">
                    <Form.Label>Status</Form.Label>
                    <Form.Select name="status" id="status"
                      value={currentTicket.status}
                      onChange={(e) =>
                        setCurrentTicket({ ...currentTicket, status: e.target.value })
                      }
                    >
                      <option value="">Choose your Status</option>
                      <option value="NEW">NEW</option>
                      <option value="CLOSED" selected="">CLOSED</option>
                      {/* <option value="QUEUED">QUEUED</option> */}
                      <option value="PENDING">PENDING</option>
                      <option value="INPROGRESS">INPROGRESS</option>
                      <option value="RESOLVED">RESOLVED</option>
                    </Form.Select>
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Save Changes
                  </Button>
                </Form>
              )}
            </Modal.Body>
          </Modal>
        </Container> : ( showAlert() )}
        <FsLightbox
				toggler={open}
				sources={attachments}
			/>
      </AdminLayout>

    </>
  );
};

export default Tickets;
